import React, { useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Become from "./Pages/become";
import Closeredirect from "./Pages/closeredirect";
import Homepage from "./Pages/home";
import Howitworks from "./Pages/howitworks";
import Rates from "./Pages/rates";
import Stories from "./Pages/stories";
import Terms from "./Pages/terms";
import Privacy from "./Pages/privacy";

const App = () => {
  document.title = "iplug";


  return (
    <Router>
      {/* <Navbar userStr={user} /> */}
      <Routes>
        <Route path="/" element={<Homepage />} exact />
        <Route path="/how-it-works" element={<Howitworks />} exact />
        <Route path="/rates" element={<Rates />} exact />
        <Route path="/stories" element={<Stories />} exact />
        <Route path="/become-a-iplug-partner" element={<Become />} exact />
        <Route path="/redirect" element={<Closeredirect />} exact />
        <Route path="/terms-and-conditions" element={<Terms />} exact />
        <Route path="/privacy-policy" element={<Privacy />} exact />
      </Routes>
      {/* <Footer /> */}
    </Router>

  )
};

export default App;