import React, { useState, useRef, useEffect } from "react";
import { Link, NavigationType, useLocation } from "react-router-dom";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { useScrollDirection } from 'react-use-scroll-direction'
import styles from './home.module.css';
import styless from './how.module.css';
import stories from "./stories.module.css";
import Header from "./header";
import { FaAngleDown, FaArrowCircleDown, FaBeer, FaFacebook, FaInstagramSquare, FaLinkedinIn, FaWhatsapp, FaYoutubeSquare } from 'react-icons/fa';
import { ReactComponent as Iphonelogo } from './../assets/applogo.svg';
import { ReactComponent as Googlelogo } from './../assets/googlelogo.svg';
import { Slide, Fade } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css'
import useOnScreen from "./detectonview";
import useWindowDimensions from "./detectwindowsize";
import Loadingspinner from "./Components/loading";
import { delay } from "./defaults";
import Zoomin from "./Components/zoomin";
import { DoneAllOutlined, ExploreOutlined, StarsOutlined } from "@mui/icons-material";


const Stories = () => {
    const { height, width } = useWindowDimensions();
    const ref = useRef()
    const ref2 = useRef()
    const bottomref = useRef()

    const [scrollup, setscrollup] = useState(false);
    const [loading, setloading] = useState(true);

    const { isScrollingDown } = useScrollDirection()

    const [scrollPosition, setScrollPosition] = useState(0);
    const handleScroll = () => {
        const position = window.pageYOffset;
        setScrollPosition(position);
    };

    setTimeout(() => {
        setloading(false)
    }, delay);

    useEffect(() => {
        scrollToTop()
        window.addEventListener('scroll', handleScroll, { passive: true });

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    function scrollToTop() {
        window.scrollTo(0, 0);
    }




    //scroll page to view all restaurants
    const viewmiddle = () => {
        document.getElementById("middle").scrollIntoView({ behavior: 'smooth' });
    }




    return (
        <div onWheel={event => {
            if (event.nativeEvent.wheelDelta > 0) {
                setscrollup(true)
            } else {
                setscrollup(false)
            }
        }}>
            {loading && <Zoomin />}
            <div className={styles.homebody}>
                <Header scrolldown={isScrollingDown} scrollPosition={scrollPosition} />
                <div className="pricingtopdiv">
                    <div className="chooseplanleftwrap">
                        <div className="chooseplantitle">Where to find Us:
                            easy access to power banks </div>

                        <br />
                        <div className="maliplusposdesc">
                            By strategically placing our power bank stations in
                            these locations, we aim to provide convenient access
                            to reliable power sources, ensuring that you can stay
                            connected and powered up wherever you go.
                        </div>

                    </div>
                    <div className="chooseplanrightwrap">
                        <img src={require("./../assets/find/map.jpg")} style={{ borderBottomLeftRadius: 200, borderTopRightRadius: 200 }} />
                    </div>
                </div>
                <br /><br />
                <div className="lighspeeddiv">
                    <div className="lighspeedleft">
                        <div>
                            <div className="titlelight">Shopping Centres</div>
                            <div className="titledescription">
                                Our power bank stations are conveniently located
                                in popular shopping centers. You can easily rent
                                a power bank while you shop, ensuring that your
                                devices stay charged throughout your shopping experience.
                            </div>
                        </div>
                    </div>
                    <div className="lighspeedright">
                        <img src={require("./../assets/find/shopping.jpg")} />
                    </div>
                </div>

                <div className="lighspeeddiv">
                    <div className="lighspeedright">
                        <img src={require("./../assets/find/festival.jpg")} />
                    </div>
                    <div className="lighspeedleft">
                        <div>
                            <div className="titlelight">Festivals</div>
                            <div className="titledescription">
                                Enjoy your favorite festivals without worrying about
                                your phone's battery life. Our power bank stations
                                are set up at festival grounds, allowing you to
                                recharge your devices and capture unforgettable moments
                                without interruption
                            </div>
                        </div>
                    </div>
                </div>

                <div className="lighspeeddiv">
                    <div className="lighspeedleft">
                        <div>
                            <div className="titlelight">Gyms</div>
                            <div className="titledescription">
                                Stay connected and energized during your workout
                                sessions. Our power bank stations in gyms provide
                                a convenient way to charge your devices while you
                                focus on your fitness routine. Never miss a beat with
                                a fully charged phone or music player.
                            </div>
                        </div>
                    </div>
                    <div className="lighspeedright">
                        <img src={require("./../assets/find/gym.jpg")} />
                    </div>
                </div>

                <div className="lighspeeddiv">
                    <div className="lighspeedright">
                        <img src={require("./../assets/find/coffee.jpg")} />
                    </div>
                    <div className="lighspeedleft">
                        <div>
                            <div className="titlelight">Coffee Shops</div>
                            <div className="titledescription">
                                Relax and recharge at your favorite coffee shop.
                                Our power bank stations are available in coffee
                                shops, ensuring that you can stay connected while
                                enjoying a cup of coffee or catching up with friends.
                                Charge your devices while you recharge yourself.
                            </div>
                        </div>
                    </div>
                </div>

                <div className="lighspeeddiv">
                    <div className="lighspeedleft">
                        <div>
                            <div className="titlelight">Restaurants and Bars</div>
                            <div className="titledescription">
                                Enhance your dining and social experiences with our
                                power bank stations available in restaurants and bars.
                                Whether you're having a meal or enjoying a night out,
                                you can keep your devices charged and capture memories
                                without worrying about battery life.
                            </div>
                        </div>
                    </div>
                    <div className="lighspeedright">
                        <img src={require("./../assets/find/hotel.jpg")} />
                    </div>
                </div>

                <div className="supportdiv">
                    <div className="qualitiesdivheader">
                        Our power bank rental service offers unmatched convenience,
                        flexibility, and an enhanced experience by providing readily
                        available power banks in various locations.
                    </div>
                    <div className="qualitiesdivminiheader">
                        With our service, you
                        can enjoy the convenience of staying charged wherever you are, ensuring
                        that your devices are always ready when you need them
                    </div>
                    <div className="qualitiesdivwrap" >
                        <div className="businessneeddiv">
                            <DoneAllOutlined sx={{ fontSize: 50 }} />
                            <div className="qualityneedtitle">Convenience</div>
                            <div className="businessneeddivmessage">
                                Our power bank rental service offers convenient access
                                to power sources in various locations, such as shopping
                                centers, festivals, gyms, coffee shops, and restaurants,
                                ensuring you never run out of battery on your devices.
                            </div>
                        </div>
                        <div className="businessneeddiv">
                            <ExploreOutlined sx={{ fontSize: 50 }} />
                            <div className="qualityneedtitle">Flexibility</div>
                            <div className="businessneeddivmessage">
                                With our power bank rental service, you have the flexibility
                                to rent a power bank whenever and wherever you need it,
                                allowing you to stay connected and powered up on the go.
                            </div>
                        </div>
                        <div className="businessneeddiv">
                            <StarsOutlined sx={{ fontSize: 50 }} />
                            <div className="qualityneedtitle">Enhanced Experience</div>
                            <div className="businessneeddivmessage">
                                By providing reliable power sources in key locations,
                                we enhance your overall experience, whether it's shopping,
                                attending festivals, working out at the gym, enjoying a coffee,
                                or dining out, ensuring you can fully utilize your devices
                                without battery concerns
                            </div>
                        </div>
                    </div><br /><br />
                </div>



                <div className={styles.bottomhomediv} ref={ref2}>
                    {width < 780 && <div style={{ marginTop: -400 }} />}
                    <div className={styles.gettheappdiv} id="middle">
                        <div className={styles.wrapappdiv}>
                            <div className={styles.getappdivtext}>GET THE APP</div>
                            <div className={styles.applogosparent2}><Iphonelogo className={styles.iphonelogo} /><Googlelogo className={styles.googlelogo} onClick={() => { window.open("https://play.google.com/store/apps/details?id=com.theiplug.iplug", "_blank") }} /></div>
                        </div>
                    </div>
                    <div className={styles.almostbottomdiv}>
                        <div className={scrollup ? styles.bottomdivfooterout : styles.bottomdivfooter} ref={bottomref}>
                            <div className={styles.wrapcontents} >
                                <div className={styles.footercontentone}>
                                    <div className={styles.footercontentonetext}>Social </div>
                                    <div className={styles.sociallinks}>
                                        <div className={styles.sociallinkdiv}>
                                            <FaFacebook />
                                        </div>
                                        <div className={styles.sociallinkdiv}>
                                            <FaInstagramSquare />
                                        </div>
                                        <div className={styles.sociallinkdiv}>
                                            <FaLinkedinIn />
                                        </div>
                                        <div className={styles.sociallinkdiv}>
                                            <FaYoutubeSquare />
                                        </div>
                                        <div className={styles.sociallinkdiv}>
                                            <FaWhatsapp />
                                        </div>
                                    </div>
                                </div>
                                <div className={styles.footercontentone}>
                                    <img src={require("../assets/blackicon.png")} />
                                </div>
                                <div className={styles.footercontentlast}>
                                    <div className={styles.footercontentonetext}>Contacts </div>
                                    <div className={styles.footercontentonemail}>mail: info@iplug.it</div>
                                </div>
                            </div>
                            <br /> <br />
                            <hr color="white" />
                            <div style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <div className={styles.footerreserved}>© 2023 Iplug ~ ORIGIN EXPLORATION TECHNOLOGIES LTD. All Rights Reserved.</div>
                            </div>
                            <div style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <div className={styles.footerreservedbuttons}>Privacy Policy</div>
                                <div className={styles.footerreservedbuttons}>Cookie Policy</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Stories;