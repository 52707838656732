import React, { useState, useRef, useEffect } from "react";
import { Link, NavigationType, useLocation, useNavigate } from "react-router-dom";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { useScrollDirection } from 'react-use-scroll-direction'
import styles from './home.module.css';
import styless from './how.module.css';
import stylesss from "./rate.module.css";
import Header from "./header";
import { FaAngleDown, FaArrowCircleDown, FaBeer, FaFacebook, FaInstagramSquare, FaLinkedinIn, FaWhatsapp, FaYoutubeSquare } from 'react-icons/fa';
import { ReactComponent as Iphonelogo } from './../assets/applogo.svg';
import { ReactComponent as Googlelogo } from './../assets/googlelogo.svg';
import { Slide, Fade } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css'
import useOnScreen from "./detectonview";
import useWindowDimensions from "./detectwindowsize";
import Loadingspinner from "./Components/loading";
import { delay } from "./defaults";
import Zoomin from "./Components/zoomin";


const Rates = () => {

    const navigate = useNavigate()
    const { height, width } = useWindowDimensions();
    const ref = useRef()
    const ref2 = useRef()
    const bottomref = useRef()

    const [scrollup, setscrollup] = useState(false);
    const [loading, setloading] = useState(true);

    const { isScrollingDown } = useScrollDirection()

    const [scrollPosition, setScrollPosition] = useState(0);
    const handleScroll = () => {
        const position = window.pageYOffset;
        setScrollPosition(position);
    };

    setTimeout(() => {
        setloading(false)
    }, delay);

    useEffect(() => {
        scrollToTop()
        window.addEventListener('scroll', handleScroll, { passive: true });

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    function scrollToTop() {
        window.scrollTo(0, 0);
    }




    //scroll page to view all restaurants
    const viewmiddle = () => {
        document.getElementById("middle").scrollIntoView({ behavior: 'smooth' });
    }




    return (
        <div onWheel={event => {
            if (event.nativeEvent.wheelDelta > 0) {
                setscrollup(true)
            } else {
                setscrollup(false)
            }
        }}>
            {loading && <Zoomin />}
            <div className={styles.homebody}>
                <Header scrolldown={isScrollingDown} scrollPosition={scrollPosition} />
                <div className={stylesss.firstdivhow}>
                    <div className={stylesss.bouncingwrap}>
                        <div className={styles.bounce} onClick={() => viewmiddle()}>
                            <FaAngleDown color="white" size={30} />
                        </div>
                    </div>
                    <div className={styles.howitworkswrap}>
                        <div className={styless.howittext}>
                            ACCESSORY THAT UNITES<br />
                            DESIGN AND HIGH<br />
                            TECHNOLOGY
                        </div>
                    </div>
                </div>
                <br /> <br />
                <div>
                    <div className={stylesss.rechargerighttoptext}>
                        Recharge your device when you need<br />
                        it and wherever you want
                        <div className={stylesss.rechargemoneytext}>50Ksh / 0-30Min</div>
                        100ksh /60min <br />
                        50Ksh /Every 30Min after 1hr<br /><br />
                        The maximum cost of renting one unit<br /><br />
                        of property for 1 day is KSH 1.5k. In case <br /><br />
                        of non-return of the leased Property within<br /><br />
                        24 hours, “ Origin” charges the User KSH 500<br /><br />
                        for each started day of using the Service <br /><br />
                        until the return of the leased Property<br /><br />

                    </div>
                    <img className={width < 780 && stylesss.rechargeimage} src={require("./../assets/Rates/test2.jpg")} />
                </div>
                <div className={stylesss.technologydiv}>
                    The cost of purchasing the property is <b>ksh 500</b> <br />
                    You will be able to use the Sharing Service again after the Iplug Powerbank is <br />
                    returned to the nearest Iplug Station.<br /><br />
                    Thanks to its innovative technology, the Iplug Powerbank recharges only inside <br />
                    theIplug Station slots.
                    <br /><br /><br />
                </div>
                <div className={width < 780 ? stylesss.bottomhomediv : styles.bottomhomediv} ref={ref2}>
                    {width < 780 && <div style={{ marginTop: -200 }} />}
                    <div className={styles.gettheappdiv} id="middle">
                        <div className={styles.wrapappdiv}>
                            <div className={styles.getappdivtext}>GET THE APP</div>
                            <div className={styles.applogosparent2}><Iphonelogo className={styles.iphonelogo} /><Googlelogo className={styles.googlelogo} onClick={() => { window.open("https://play.google.com/store/apps/details?id=com.theiplug.iplug", "_blank") }} /></div>
                        </div>
                    </div>
                    <div className={styles.almostbottomdiv}>
                        <div className={scrollup ? styles.bottomdivfooterout : styles.bottomdivfooter} ref={bottomref}>
                            <div className={styles.wrapcontents} >
                                <div className={styles.footercontentone}>
                                    <div className={styles.footercontentonetext}>Social </div>
                                    <div className={styles.sociallinks}>
                                        <div className={styles.sociallinkdiv}>
                                            <FaFacebook />
                                        </div>
                                        <div className={styles.sociallinkdiv}>
                                            <FaInstagramSquare />
                                        </div>
                                        <div className={styles.sociallinkdiv}>
                                            <FaLinkedinIn />
                                        </div>
                                        <div className={styles.sociallinkdiv}>
                                            <FaYoutubeSquare />
                                        </div>
                                        <div className={styles.sociallinkdiv}>
                                            <FaWhatsapp />
                                        </div>
                                    </div>
                                </div>
                                <div className={styles.footercontentone}>
                                    <img src={require("../assets/blackicon.png")} />
                                </div>
                                <div className={styles.footercontentlast}>
                                    <div className={styles.footercontentonetext}>Contacts </div>
                                    <div className={styles.footercontentonemail}>mail: info@originafrica.com</div>
                                    <div className={styles.footercontentonemail}>tel:  +254 0115066574</div>
                                    <div className={styles.footercontentonemail}>Physical Address: Krishna Centre, Woodvale Grove, Westlands, Nairobi, Kenya</div>
                                    <div className={styles.footercontentonemail}>Postal Address: P.O. Box 4215 – 00100 NAIROBI</div>
                                </div>
                            </div>
                            <br /> <br />
                            <hr color="white" />
                            <div style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <div className={styles.footerreserved}>© 2023 Iplug ~ ORIGIN EXPLORATION TECHNOLOGIES LTD. All Rights Reserved.</div>
                            </div>
                            <div style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <div className={styles.footerreservedbuttons} onClick={() => navigate("/terms-and-conditions")}>Terms & Conditions</div>
                                <div className={styles.footerreservedbuttons} onClick={() => navigate("/privacy-policy")}>Privacy Policy</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Rates;