import { Backdrop, CircularProgress } from "@mui/material"
import styles from './../home.module.css';

const Zoomin = () => {

    return (
        <Backdrop
            sx={{ color: '#000000', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={true}
        >
             <div className={styles.backdrop}>
                    <img src={require("./../../assets/bigblack.png")} />
                </div>
        </Backdrop>
    )
}

export default Zoomin