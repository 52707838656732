import React, { useState, useRef, useEffect } from "react";
import { Link, NavigationType, useLocation, useNavigate } from "react-router-dom";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { useScrollDirection } from 'react-use-scroll-direction'
import styles from './home.module.css';
import styless from './how.module.css';
import stylesss from "./rate.module.css";
import Header from "./header";
import { FaAngleDown, FaArrowCircleDown, FaBeer, FaFacebook, FaInstagramSquare, FaLinkedinIn, FaWhatsapp, FaYoutubeSquare } from 'react-icons/fa';
import { ReactComponent as Iphonelogo } from './../assets/applogo.svg';
import { ReactComponent as Googlelogo } from './../assets/googlelogo.svg';
import { Slide, Fade } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css'
import useOnScreen from "./detectonview";
import useWindowDimensions from "./detectwindowsize";
import Loadingspinner from "./Components/loading";
import { delay } from "./defaults";
import Zoomin from "./Components/zoomin";


const Terms = () => {
    const navigate = useNavigate()
    const { height, width } = useWindowDimensions();
    const ref = useRef()
    const ref2 = useRef()
    const bottomref = useRef()

    const [scrollup, setscrollup] = useState(false);
    const [loading, setloading] = useState(true);

    const { isScrollingDown } = useScrollDirection()

    const [scrollPosition, setScrollPosition] = useState(0);
    const handleScroll = () => {
        const position = window.pageYOffset;
        setScrollPosition(position);
    };

    setTimeout(() => {
        setloading(false)
    }, delay);

    useEffect(() => {
        scrollToTop()
        window.addEventListener('scroll', handleScroll, { passive: true });

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    function scrollToTop() {
        window.scrollTo(0, 0);
    }




    //scroll page to view all restaurants
    const navigateto = (path) => {
        document.getElementById(path).scrollIntoView({ behavior: 'smooth' });
    }




    return (
        <div onWheel={event => {
            if (event.nativeEvent.wheelDelta > 0) {
                setscrollup(true)
            } else {
                setscrollup(false)
            }
        }}>
            {loading && <Zoomin />}
            <div className={styles.homebody}>
                <Header scrolldown={isScrollingDown} scrollPosition={scrollPosition} />
                <div className="wraptermswhole">
                    <div className="termsleft">
                        <div className="termstext">Home\General Terms & Conditions</div>
                        <h1>PREAMBLE</h1>

                        <p>
                            These general terms and conditions constitute a legally binding agreement between Origin Exploration Technologies Kenya
                            Limited hereafter referred to as (We, our or Origin) and You for provision of the Services, unless otherwise agreed in
                            writing by Us and You. Please read carefully. Please do not use any part of the Services if You do not agree with any
                            term of this Agreement.
                        </p>
                        <p>
                            By using or continuing to use any part of the Services, You will be deemed to have read, understood and accepted all
                            the terms of this Agreement.
                        </p>
                        <p>NOW THEREFORE, You agree that;</p>


                        <h2>1. TERMS AND INTERPRETATIONS</h2>

                        <p>
                            <strong>1.1. Service</strong> - it is an information system and programs (applications) for electronic computers, which
                            are used for concluding and organizing mediation in concluding lease and sale agreements of Property, popularization
                            of culture of use of power banks located on the territory of KENYA, under the name ”ORIGIN”.
                        </p>
                        <p>
                            <strong>1.2. ”ORIGIN” / Administrator</strong> - it is a limited liability Company
                        </p>
                        <p>
                            <strong>1.3. User</strong> - this is any individual who has reached 18 years of age and has full capacity, has opened
                            the software application ”ORIGIN”, registered (created a Profile) and uses the Service, as well as agreed to the
                            terms of the User Agreement and integral annexes to it.
                            An individual who has not reached the required age undertakes to refrain from using the Service. Otherwise, the
                            responsibility for violation of the terms of the Agreement by a natural person who has not reached the required age
                            rests with his parents, adoptive parents, guardians, trustees or other legal representatives in accordance with the
                            current legislation of KENYA.
                        </p>
                        <p><strong>1.4. Profile</strong> - this is the User account.</p>
                        <p><strong>1.5. Agreement</strong> - the text of this agreement between the User and the Administrator, which contains
                            all necessary and essential conditions of use of the Service and lease and sale of property, restrictions, conditions
                            of suspension and termination of use of the Service, responsibility of the Parties, other relations of the Parties.
                        </p>
                        <p><strong>1.6. Parties</strong> - the Administrator and the User who are parties to this Agreement.</p>
                        <p><strong>1.7. Property</strong> - it is an external charger for mobile devices owned by the Administrator.</p>
                        <p><strong>1.8. Intellectual Property</strong> - these are the results of intellectual, creative activity, namely:
                            objects of copyright; objects of related rights; objects of industrial property rights; means of individualization,
                            including, but not limited to, trademarks, know-how, brand names, inventions, utility models, industrial designs,
                            software code, audiovisual effects, design themes, decoration, graphics, sound effects, music with text and without
                            text), both registered and unregistered, as well as all appendices, additions and modifications to them; intellectual
                            property rights belonging to the Service Administrator.
                        </p>
                        <p><strong>1.9. Unique ID</strong> - it is an alphanumeric sequence received by the User who accepted the offer to enter
                            into an electronic agreement by registering in the Service. The one-time identifier is transmitted by the means of
                            communication specified by the User at registration and is attached (attached) to the e-mail from the person who
                            accepted the offer to enter into the contract.
                        </p>
                        <p><strong>1.10. Authorized User Device</strong> - a device on which the original software application ”ORIGIN” is
                            installed
                        </p>
                        <p><strong>1.11. Consent</strong> - this means that the User has given his explicit consent to the processing of his
                            personal data for a specific purpose. If the User has previously given consent to ”ORIGIN” to process his personal
                            data, the User has the right to freely revoke such consent at any time by sending a notice to that effect. If the
                            User withdraws his consent, and ”ORIGIN” has no other legal grounds for processing his personal information,
                            ”ORIGIN” will stop processing personal data of the User. If ”ORIGIN” has another legal basis for processing the
                            User's information, ”POWER has another legal basis for processing the User's information, ”ORIGIN” may continue to
                            do so in accordance with the legal rights of the User.
                        </p>
                        <p><strong>1.12. Contract</strong> - it is this agreement according to which the User's data necessary for the execution
                            of said agreement with ”ORIGIN” or because ”ORIGIN” has requested the User to take specific steps before concluding
                            this agreement is processed.
                        </p>
                        <p><strong>1.13. POS</strong> means payments accepted on premise through paybill numbers or mobile banking</p>
                        <p><strong>1.14. Legitimate interests</strong> - processing of the User's personal data ”ORIGIN” or a third party, if
                            these interests do not prevail over the rights and interests of the User. Legitimate interests include:
                            <br />
                            - obtaining information about the User's behavior on the Site
                            <br />
                            - development and improvement of the Site
                            <br />
                            - interest that allows ORIGIN to improve, configure or modify services and communications
                            <br />
                            - determining the effectiveness of marketing campaigns ORIGIN
                            <br />
                            - increase data security.
                            <br />
                            Legitimate interests act only in cases where they do not outweigh the rights and interests of the User.
                        </p>


                        <h2>2. GENERAL PROVISIONS</h2>

                        <p>
                            <strong>2.1. The Agreement</strong> applies to all Users of the Service, regardless of the purposes of its use. The
                            Agreement applies to Users from the moment of registration in the Service. Upon registration, the User is sent a
                            one-time identifier, which must be entered next to the ”Register” button. Clicking the ”Register” button means full
                            and unconditional consent of the User to the Agreement. In case of disagreement with the Agreement, the User
                            undertakes to immediately stop using the Service.
                        </p>
                        <p>
                            <strong>2.2. The subject of the Agreement</strong> is the relationship between ”ORIGIN” and the User regarding the use
                            of the Service and the organization of mediation of lease and sale of property, popularization of the culture of
                            using power banks, as well as the User's use of other services.
                        </p>
                        <p>
                            <strong>2.3. The current version of the Agreement</strong> is permanently posted at the address on the Internet:
                            origin website and directly in the Service. ”ORIGIN” reserves the right to change or supplement this Agreement at
                            any time without prior or subsequent notice. The User independently monitors changes to the Agreement and gets
                            acquainted with the current version of the Agreement. Continuation of use of the Service by the User after making
                            changes and/or additions to this Agreement means acceptance and consent of the User with such changes and/or
                            additions.
                        </p>


                        <h2>3. REGISTRATION</h2>

                        <p>
                            <strong>3.1. To gain access to the Service</strong>, the User registers the Profile in the Service. User registration
                            is free.
                        </p>
                        <p>
                            <strong>3.2. Registration</strong> is a voluntary action of the User to accept the terms of the User Agreement.
                            Registration is carried out by entering the User's mobile phone number in the format (+ 254), which receives a
                            message with a six-digit access code or QR CODE for identification. The User is prohibited from providing inaccurate
                            information, personal data of other persons, as well as using payment cards that do not belong to the User during
                            registration. The User guarantees the accuracy and precision of the data specified during registration, while
                            ”ORIGIN” does not check the information provided by the latter, and the responsibility for violating the terms of
                            this paragraph of the Agreement rests with the User.
                        </p>
                        <p><strong>3.3. Registration</strong> is done by entering an access code/ QR CODE, which is a unique user ID.</p>
                        <p><strong>3.4. The next login</strong> to the Profile is by authorization.</p>
                        <p>
                            <strong>3.5. Changing the password</strong>, registration data, adding other information about the User, including
                            personal data, is possible in the Profile after registration. The profile is a means of individual accounting of the
                            User's payments, which provides reflection and accounting of the receipt and write-off of funds for the payment of
                            rent and sale of the Property.
                        </p>
                        <p>
                            <strong>3.6. The user is fully responsible</strong> for the security of identification data/ QR CODE in particular
                            for the lack of access to them by third parties. All actions in the Service performed under the User Profile are
                            considered to be performed by the User.
                        </p>
                        <p>
                            <strong>3.7. The User has the right to terminate</strong> the use of the Service and refuse the Profile created by
                            him by sending a request to ORIGIN to the e-mail address support@originemail.ke to remove the Profile from the
                            Service. ORIGIN deletes the User Profile within 30 calendar days from the date of receipt of the request.
                        </p>
                        <p>
                            <strong>3.8. The User's use of the Service</strong>, any of its services, means the unconditional consent of the User
                            with all clauses of the Agreement. In case of disagreement of the User with any of the terms of this Agreement, the
                            User is obliged to refuse further use of the Service.
                        </p>
                        <p>
                            <strong>3.9. Registration</strong> of the User in the information system of ”ORIGIN” means giving his consent to the
                            processing of his personal data, as well as accepting the terms of this Agreement.
                        </p>



                        <h1>4. RIGHTS AND OBLIGATIONS OF THE PARTIES</h1>
                        <p>4.1. <b>"ORIGIN"</b> provides Users with intermediary services for concluding Property lease agreements and concludes property purchase and sale agreements, provides the required level of access security and maintenance breaks, in the manner and under the conditions provided for in this Agreement.</p>
                        <p>4.2. The User agrees with the obligation to immediately notify <b>"ORIGIN"</b> of any security breach related to access to the Service and/or committed using the User's phone number without his knowledge and consent. <b>"ORIGIN"</b> does not assume responsibility for any consequences of security breaches, including loss or damage to data that occurred as a result of third-party access to the Service using the User ID code.</p>
                        <p>4.3. <b>"ORIGIN"</b> provides automated means of access to the service. At the same time, <b>"ORIGIN"</b> reserves the right to restrict the use of these funds, as well as to temporarily or completely suspend their operation. <b>"ORIGIN"</b> is not responsible for the loss of information for access to the Service, made through the fault of the User, as well as for any consequences that may arise as a result.</p>
                        <p>4.4. The User agrees with the right of <b>"ORIGIN"</b> to prohibit the use of certain logins (phone numbers) and block access to the Service with their use.</p>
                        <p>4.5. The User undertakes to comply with the terms of this Agreement and agrees that <b>"ORIGIN"</b> has the right to restrict the User's access to the Service at its own discretion and without special notice or warning.</p>
                        <p>4.6. The User agrees that <b>"ORIGIN"</b> has the right in its sole discretion and without prior or subsequent notice to the User and explanation of the reasons to take other measures against the User who violated the terms of this Agreement or applicable law or legally protected rights of third parties in case of receipt of a motivated complaint from them without compensation to the User of the prepaid cost of services on the terms of the subscription.</p>
                        <p>4.7. Unless otherwise expressly provided by the mandatory legislation of KENYA, in case of violation by the User of the Agreement, <b>"ORIGIN"</b> has the right to take measures necessary to protect these conditions and its interests, regardless of the statute of limitations, and any measures that do not contradict the current legislation, in order to prevent unauthorized access to the Service, destabilize the Service and other actions that may violate the rights and legitimate interests of <b>"ORIGIN"</b>. Thus, inaction on the part of <b>"ORIGIN"</b> in violation of the Terms of the Agreement by Users does not deprive <b>"ORIGIN"</b> of the right to take necessary actions to protect their interests later and does not indicate the waiver of <b>"ORIGIN"</b> from their rights during such violations in the future.</p>

                        <h2>5. PROCEDURE FOR RENTING AND SELLING PROPERTY</h2>
                        <p>5.1. To lease the Property, the User must find any station for the location of the Property through the Service using geolocation and use the Service to obtain the Property directly from the station.</p>
                        <p>5.2. Upon completion of the use of the Property, the User is obliged to return the Property to one of the stations, in particular: to find any Station for placement of the Property through the Service using geolocation and to return the Property directly to the station using the Service. The user must be able to provide information about the location of the abandoned property at the request of <b>"ORIGIN"</b> within 24 hours after the end of the lease period. If the User has not returned the Property in the prescribed manner or provided false information, or has not completed the return procedure in full, the service fee will be charged to the User in accordance with the current tariffs of <b>"ORIGIN"</b>.</p>
                        <p>5.3. It is forbidden to make modifications and re-equipment of the Property. In case of unauthorized or incorrect use of the Property, <b>"ORIGIN"</b> has the right to terminate all business relations with the User and block further use of the Services.</p>
                        <p>5.4. After confirming the return of the Property, the User has the right to release the Property in the general order. To do this, you need to go through all the stages of the lease again.</p>
                        <p>5.5. Lease restrictions: each User has the right to rent 1 unit of Property at a time; the period of lease of the Property starts from the moment of receiving the Property from the Station and ends at the moment of returning the Property to any Station. In case of non-return of the Property after the expiration of the lease term, for each next 24 hours within 14 days, the amount of KSH 500 is automatically deducted from the User's bank card. After the expiration of the 14-day period, the Property is considered to be the property acquired by the User, subject to compliance with the procedure established by Clause 6.</p>
                        <p>5.6. Terms of lease of the Property: The user must carefully read the terms of the lease and use of the Property; before using the Property, the User must check the technical condition of the Property and its operability.</p>
                        <p>5.7. In case of detection of damage and (or) technical malfunction of the Property, the User must immediately inform <b>"ORIGIN"</b>. The user is not responsible for detecting damage if he has not been informed within 48 hours after the lease term. The User is liable for damages caused by theft or damage to the Property within a 24-hour period after the rental period, during which the Property must be inspected by an employee of the Landlord.</p>
                        <p>5.8. If the Property was stolen during the lease period, the User must report the theft to <b>"ORIGIN"</b> and the police. After completing the abduction report, the User must notify the Landlord of the statement number. In this case, the User is released from the liability specified in paragraph 5.5. of this Agreement.</p>.
                        <h2>6. PAYMENTS FOR SERVICES</h2>
                        <p>6.1. With the help of the Service, the User can rent or buy the Property.</p>
                        <p>6.2. The cost of rent and purchase and sale of a unit of property is formed by <b>"ORIGIN"</b> in tariffs (tariff plans). The user can get acquainted with the information list of the current tariff plans on the corresponding page "ORIGIN," which is located at: <a href="originwebsite.ke">originwebsite.ke</a></p>
                        <p>6.3. The user can get acquainted with the cost of purchase and sale of the Property unit on the corresponding page "ORIGIN," located at the address: <a href="originwebsite.ke">originwebsite.ke</a></p>
                        <p>6.4. Information on completed leases of the Property can be viewed by the User at any time in the Profile.</p>
                        <p>6.5. Payments under the User Agreement are made by debiting funds from the User's bank card or POS. <b>"ORIGIN"</b> has the right to involve agents (payment gateway systems) in the process of accepting payments.</p>
                        <p>6.6. The user must bind (enter data) to Service bank card (or multiple bank cards) that automatically integrate with Service and from which funds will be deducted from the payments provided for in the agreement.</p>
                        <p>6.7. It is allowed to use bank cards only for those payment systems that are listed on the site Service or directly in the application for a smartphone with the ability to make online payments.</p>
                        <p>6.8. The possibility of making online payments is provided by the User independently through the bank issuing the bank card. Cash in the account of payments provided by the Agreement is debited to <b>"ORIGIN"</b> from the bank card tied by the User and selected as the main bank card. Debiting funds from the User's account is automatic.</p>
                        <p>6.9. When the User links each bank card, as well as before each use of the Service, a positive balance can be checked on the User's bank card, for which a sum of no more than 205 KSH is debited from the User's card within 24 hours from the moment of debiting.</p>
                        <p>6.10. Debiting funds from the User's bank card is carried out in the amounts provided by the current Tariffs and other terms of the Agreement. Amounts of taxes and other mandatory payments (if required by law) may be added to the amount of Debit from the User's bank card. Debiting of funds may be carried out both during the lease of the Property and after the termination of the lease of the Property by the User, in case of violation by the User of the terms of the User Agreement (fines) and in other cases established by the Agreement. ORIGIN has the right to notify the User of the fact of debiting funds in all cases, however, the User undertakes to connect the service of SMS-notification of debiting from the card through the bank-issuer of the bank card and independently monitor these write-offs.</p>
                        <p>6.11. <b>"ORIGIN"</b> has the right to debit the User's bank card of payments due to him at any time before the end of the lease.</p>
                        <p>6.12. At the written request of the User, based on the results of debiting funds from the bank card of the User, <b>"ORIGIN"</b> should send to the e-mail address of the User specified during registration in Service, an electronic check or other document confirming the fact of providing services / renting the property is sent.</p>
                        <p>6.13. In case of insufficiency of funds on the User's bank card, <b>"ORIGIN"</b> notifies the User by sending a corresponding SMS message to the User. This information is also displayed in the Annex. The User undertakes to repay the arising debt within 24 hours from the date of submission of the relevant notice to "ORIGIN," providing sufficient to write off the balance on the bank card, while until the repayment of the debt that arose <b>"ORIGIN"</b> has the right to assign the User the status of a debtor and block his access to the Service in whole or in part.</p>
                        <p>6.14. The User may be denied to make payments using a bank card in the cases provided by law and this Agreement, in particular, in the absence of a bank card option to make online payments; lack of funds on the bank card; incorrect input of bank card data; expiration of the bank card, etc.</p>
                        <p>6.15. The User may be denied to make payments using a bank card in the cases provided by law and this Agreement, in particular, in the absence of a bank card option to make online payments; lack of funds on the bank card; incorrect input of bank card data; expiration of the bank card, etc.</p>
                        <p>6.16. In case of non-fulfillment by the User of the payment obligations stipulated by this Agreement, <b>"ORIGIN"</b> has the right to send the relevant information (including the User's personal data) to the authorized bodies for bringing the User to administrative responsibility, as well as to the organizations collecting funds.</p>
                        <p id="refund">6.17. In case of erroneous debiting of funds from the User's bank card, the User has the right to contact <b>"ORIGIN"</b> or by phone to the Support Service of "ORIGIN," <b>"ORIGIN"</b> has the right to require the User to provide a copy of passport and supporting documents. erroneous write-off (account statements, etc.). Cash refunds are not allowed.</p>
                        <p>6.18. Appeals against written-off expenses must be submitted to <b>"ORIGIN"</b> in writing within 14 days from the moment of payment. The User's claims after the expiration of the specified period, even if not justified, are not valid and are not accepted. In case of return of funds to the User, they will be credited to his account and will be used for subsequent payment, unless the User provides any other instructions.</p>
                        <p>6.19. The currency of accounting for services provided is the Kenyan shilling.</p>
                        <p>6.20. <b>"ORIGIN"</b> has the right to change the number of tariff plans or payments established by item 5.5. Agreements for new periods without special notice to the User. <b>"ORIGIN"</b> has no right to change the amount of already paid rent or payments established by item 5.5.</p>
                        <p>6.21. The User understands and agrees that the amount of payment for rent/sale of the Property does not include the payment of possible fees to third parties who make payments for services. The amount of the commission is determined by the person who makes payments for services.</p>
                        <p>6.22. <b>"ORIGIN"</b> has the right to provide loyalty programs, promotions, and incentive offers for the users of the Service, with more detailed information about which can be found on the Service and/or in the User Profile.</p>
                        <p>6.23. <b>"ORIGIN"</b> reserves the right to change and supplement the terms of loyalty programs, the availability of gifts, at any time without any notice to the User.</p>


                        <p>7. INTELLECTUAL PROPERTY</p>
                        <p>7.1. All intellectual property rights to use the Service belong to <b>"ORIGIN"</b>.</p>
                        <p>7.2. The User has no right to reproduce the Service (including copying / downloading) in the memory of electronic devices of users, sale, other alienation, public display, public performance, public notice, communication, as well as the right to other using of these results intellectual activity not defined in this Agreement, including their sale, modification, distribution in whole or in part, etc.</p>
                        <p>7.3. All rights not expressly set forth in this Agreement shall be deemed not to have been granted to the User.</p>
                        <p>7.4. <b>"ORIGIN"</b> reserves all rights, property rights and exclusive intellectual property rights to Intellectual Property owned by <b>"ORIGIN"</b>, including, inter alia, all copyrights, trademark rights, know-how, brand (commercial) names, patents, software code, audiovisual effects, design themes, art design, graphics, sound effects, musical works, both registered and unregistered, as well as all applications and additions and modifications to them. Unless otherwise expressly permitted by the conditions specified in the provisions of Kenyan law.</p>
                        <p>7.5. <b>"ORIGIN"</b> reserves the right to block any information posted by the User that violates the intellectual, exclusive and/or personal non-property rights of third parties, including copyright and related rights, at the request of the right holder or without such.</p>

                        <p>8. PERSONAL DATA</p>

                        <p>8.1. <b>"ORIGIN"</b> processes their personal data in accordance with the provisions of this section in order to comply with the provisions of this Agreement and provide Users with access to the Service.</p>
                        <p>Within the framework of this Agreement, "Personal Information" (for the purposes of this Policy, the term Personal Information, unless otherwise follows directly from the provisions of the Policy, is synonymous with personal data as defined in the legislation of KENYA):</p>
                        <p>8.1.1. personal information provided by the User can provide about themselves independently when creating the Profile and (or) in the process of using the Service, including surname, name, patronymic, contact details (phone number);</p>
                        <p>Legal basis for the use of such data: Contract, Consent.</p>
                        <p>8.1.2. standard data that is automatically received by the http/https-server when accessing the Service and further actions of the User (IP-address, MAC-address, type of the User's operating system, pages of the Service visited by the User, geolocation of the User);</p>
                        <p>Legal basis for the use of such data: Legitimate interests, Consent.</p>
                        <p>8.1.3. information traffic between the User and <b>"ORIGIN"</b>, including commercial electronic messages and electronic messages related to the Service (for example, account confirmation, technical messages and security system information, etc.);</p>
                        <p>Legal basis for the use of such data: Legitimate interests, Consent.</p>
                        <p>8.1.4. for the purposes of making payments and for the purpose of processing data on payments made by Users, <b>"ORIGIN"</b> may collect information on payment details of the User, including data on the fact of payment made through third party payment systems, as well as individual details the payer;</p>
                        <p>Legal basis for the use of such data: Contract, Consent.</p>
                        <p>8.1.5. <b>"ORIGIN"</b> may collect and process data on the User's device identifiers;</p>
                        <p>8.2. <b>"ORIGIN"</b> should not check the

                            accuracy of the Personal Information provided by Users and does not exercise control over the legal capacity of Users.</p>
                        <p>8.3. <b>"ORIGIN"</b> assumes that the User:</p>
                        <p>8.3.1. provides reliable and sufficient Personal Information, including on the issues proposed in the form for the creation of the Profile, and maintains this information up to date;</p>
                        <p>8.3.2. has all the necessary rights that allow him to register and use the Service;</p>
                        <p>8.4. <b>"ORIGIN"</b> processes Personal Information for the purpose of fulfilling the Agreement between <b>"ORIGIN"</b> and the User. In accordance with the provisions of the Law of KENYA on Data Protection. The User's consent to the processing of his Personal Information is given upon registration in the information and technology system of the Service ORIGIN by marking the permission to process personal data in accordance with the stated purpose of processing.</p>
                        <p>8.5. <b>"ORIGIN"</b> strictly adheres to the requirements of the legislation of KENYA in the field of data protection. <b>"ORIGIN"</b> guarantees that the personal information collected by <b>"ORIGIN"</b> is necessary and sufficient to achieve the collection and processing objectives set forth in this Policy.</p>
                        <p>8.6. <b>"ORIGIN"</b> collects and processes Personal Information for the following purposes:</p>
                        <p>8.6.1. providing the possibility of using the Service by Users, conducting advertising campaigns, providing targeted advertising, conducting statistical research and analysis of the obtained statistical data on the basis of impersonal data;</p>
                        <p>8.6.2. providing information services to the User;</p>
                        <p>8.6.3. improving the quality of the Service, ease of use;</p>
                        <p>8.6.4. communication with the User, including sending messages, requests and information related to the use of the Service, as well as processing the User's requests;</p>
                        <p>8.6.5. improving the quality of the Service, ease of use, development of new Services and other similar products;</p>
                        <p>8.6.6. sending information (commercial e-mails), including by e-mail, as well as sending push messages, sms messages, making calls to the phone number specified in the Profile. Such mailings can be carried out using the technical infrastructure (servers) of <b>"ORIGIN"</b>, as well as third parties. In case of unwillingness to receive the newsletter, the User has the right to contact <b>"ORIGIN"</b> by writing a statement of refusal to receive advertising materials, sending it to the postal address of <b>"ORIGIN"</b>, or e-mail support@originwebsite.com, as well as The user may opt-out of receiving such messages directly by following the hyperlink from the commercial email.</p>
                        <p>8.7. The user agrees that <b>"ORIGIN"</b> in the process of processing personal data has the right to carry out the following actions with personal data: collection, systematization, accumulation, storage, use, transfer to third parties, including outside KENYA, destruction and other necessary actions in order to fulfill the Agreement and use the Service.</p>
                        <p>8.8. <b>"ORIGIN"</b> may allow third parties, including publishers and advertising networks, to place advertisements on the Service. At the same time, <b>"ORIGIN"</b> guarantees that in case of placement of Cookie files by the specified persons on the User's Device, the transfer of Personal Information is not carried out.</p>
                        <p>8.9. The User agrees that the contact details provided by him during registration from the moment of their entry on the Service and confirmation will be automatically included in the newsletter system from <b>"ORIGIN"</b>, including advertising and other information.</p>


                        <p>8.10. The user has the right to request from <b>"ORIGIN"</b> personal data collected by <b>"ORIGIN"</b> by writing an application for such data, sending it to the postal address of <b>"ORIGIN"</b>, or e-mail support. The term for processing and responding to such a request is up to 30 (thirty) calendar days from the date of its receipt by <b>"ORIGIN"</b>.</p>
                        <p>8.11. Collection of Personal Information of the User is carried out by <b>"ORIGIN"</b> at registration, and also further at entering by the User on the initiative of additional information on itself.</p>
                        <p>8.12. Personal data and information of Users are stored exclusively on electronic media and processed using automated systems, except when non-automated processing of personal data is required in connection with compliance with the law.</p>
                        <p>8.13. <b>"ORIGIN"</b> in the process of processing Personal Information performs the following actions: collection, recording, systematization, accumulation, storage, clarification, retrieval, use, transfer, depersonalization, blocking, deletion, destruction of Personal Information and other actions solely for the purpose of fulfilling the User Agreement and providing access to the Service.</p>
                        <p>8.14. <b>"ORIGIN"</b> does not disclose to third parties and does not disseminate Personal Information without the consent of users (Except as provided by the legislation of KENYA and this Agreement). In addition, even with the consent of the User, <b>"ORIGIN"</b> does not post Personal Information on publicly available resources on the Internet, as such posting does not meet the purposes of collecting and processing Personal Information. <b>"ORIGIN"</b> guarantees that access to Personal Information is not provided to a third party if the said person refuses to undertake to ensure compliance with the requirements of the Law of KENYA "On Personal Data Protection" or cannot provide them. In no case will <b>"ORIGIN"</b> be liable for the consequences of the User's personal provision of personal information for public access to an unlimited number of persons, including by posting this information on publicly available resources on the Internet.</p>
                        <p>In any case, the User has the right to request from <b>"ORIGIN"</b> information on the conditions of granting access to his personal data, in particular information about third parties to whom his personal data is transferred.</p>
                        <p>8.15. <b>"ORIGIN"</b> takes reasonable and sufficient measures to protect Users' Personal Information from accidental loss or destruction, from illegal processing, including illegal destruction or access to personal data.</p>
                        <p>At the same time, in order to ensure the possibility of the normal use of the Service, <b>"ORIGIN"</b> may use the services of third parties. In no case will <b>"ORIGIN"</b> be liable for breach of confidentiality of Personal Information that occurred accidentally or as a result of illegal actions of third parties. The User understands and understands that all Personal Information is provided to them at their own risk and personal responsibility, and in the event of any wrongful acts of third parties regarding Personal Information, the User agrees to claims and lawsuits directly against such persons.</p>
                        <p>In this case, the User understands and agrees that the Internet can not be provided absolute protection of information from the threats that exist in it. The User hereby gives his unconditional and irrevocable consent to <b>"ORIGIN"</b> to determine a sufficient level of personal data protection, methods and place (territory) of their storage.</p>
                        <p>8.16. The processing of the User's personal data is carried out by <b>"ORIGIN"</b> from the moment of registration of the Profile and within 3 (three) years from the moment of deletion of the Profile by the User, which is the general statute of limitations.</p>
                        <p>8.17. The

                            User is responsible for maintaining the confidentiality of the login and password of the Profile, as well as for all actions performed under his login and password on the Service. In case of loss of the User's login or password, the User has the right to restore access to the Profile in the manner prescribed by the Service. The User has the right to change the login and password at any time on his own initiative using the tools provided by the Service.</p>
                        <p>8.18. The User has the right to withdraw consent to the processing of personal data by deleting the Profile on the Service. To delete the Profile, the User must contact the support service by sending a corresponding message to the postal address of <b>"ORIGIN"</b> or e-mail support@originwebsite.com.</p>
                        <p>8.19. In case of withdrawal of consent to the processing of personal data or deletion of the Profile, <b>"ORIGIN"</b> has the right to continue processing personal data without the consent of the User if there are grounds specified in the legislation of KENYA.</p>
                        <p>8.20. <b>"ORIGIN"</b> has the right to use Cookie files to identify the User and improve the efficiency of the Service. By using the Service, the User agrees to the processing of Cookie files by <b>"ORIGIN"</b>.</p>
                        <p>8.21. The User understands that the use of the Service may involve the processing of his personal data by third parties, including outside KENYA, and agrees to such processing. The list of actions for the processing of personal data may be expanded at the discretion of <b>"ORIGIN"</b>.</p>
                        <p>8.22. The User understands and agrees that in the event of a change in the legislation of KENYA, as well as a change in the terms of this Agreement, the User's consent to the processing of personal data is automatically considered given by him in accordance with the new edition of the Agreement and/or legislation of KENYA.</p>


                        <h1>9. TECHNICAL REQUIREMENTS</h1>
                        <p>9.1. The User confirms and agrees that access to the Service is provided to the User only through the Authorized Devices of the User.</p>
                        <p>9.2. Authorized device of the User for the correct operation of the Service must be connected to the Internet at a speed not lower than 1 megabit per second;</p>
                        <p>9.3. The User acknowledges and agrees that the speed of access to the Service on the Internet may change due to the load of the User's channel with traffic from other resources or for other reasons not dependent on <b>"ORIGIN"</b>.</p>
                        <p>9.4. All issues related to the acquisition of Internet access are resolved by the User independently.</p>

                        <h1>10. PROHIBITION OF USE OF THE SERVICE</h1>
                        <p>10.1. The user is prohibited from:</p>
                        <p>10.1.1. In any way interfere with the operation of the Service, including the creation, distribution or use of computer programs or other computer information intended for unauthorized destruction, blocking, modification, copying of computer information or neutralization of computer information security, as well as intended to disrupt, destroy or limit the functionality of any computer or telecommunications equipment or programs (computer viruses), for unauthorized access, as well as serial numbers to commercial software products and programs for their generation, login, passwords and other means to obtain unauthorized access to the Service, as well as the placement of links to the above information.</p>
                        <p>10.1.2 Sell, otherwise alienate or transfer for use to third parties Profile. Granting third parties access to the Profile is a gross violation of the Agreement. <b>"ORIGIN"</b> reserves the right to block such User Profile without prior notice and/or block the acceptance of payment by the User's payment cards or use other methods to prevent unauthorized transfer of access to the Profile to other Users or third parties.</p>
                        <p>10.1.3. To commit or allow the commission of criminal or destructive acts prosecuted in accordance with the legislation of KENYA.</p>
                        <p>10.1.4. Upload, store, use or transmit any information that infringes the intellectual property rights of third parties.</p>
                        <p>10.1.5. Impersonate another person or a representative of the organization and/or community, as well as mislead other Users.</p>
                        <p>10.1.6. Collect and store information about other Users outside the Service in manual or automated mode.</p>
                        <p>10.2. By using the Service, the User undertakes not to violate its information security, namely not to perform the following actions:</p>
                        <ul>
                            <li>not to make attempts to check the vulnerability of the Security systems of the Service, not to violate the registration procedures in the Service;</li>
                            <li>do not attempt to interfere with the use of the Service by other Users, including the spread of computer viruses, data corruption, constant sending of duplicate information, simultaneous sending of large e-mails and/or requests to the Service in order to intentionally disable the site / Service server and others actions that go beyond the normal intended use of the Service and are capable of intentionally or negligently causing failures in its work;</li>
                            <li>not to send to the Users of the Service materials to which they did not give their consent, any letters and advertisements without the permission of <b>"ORIGIN"</b>;</li>
                            <li>not to imitate and/or forge any TCP / IP packet header or any part of the header in any e-mail or material posted on the Service;</li>
                            <li>do not

                                use or attempt to use any software or procedures to navigate or search the Service, other than the built-in Search Engine and traditional and public browsers.</li>
                        </ul>
                        <p>10.3. Violation of the provisions of clauses 9.1 - 9.3 of this Agreement is the basis for unilateral refusal of <b>"ORIGIN"</b> from its further performance without compensation to the User of the prepaid cost of services on the terms of subscription.</p>

                        <h1>11. LIABILITY AND LIMITATIONS OF LIABILITY</h1>
                        <p>11.1. <b>"ORIGIN"</b> is not responsible in case of direct or indirect financial or other losses of Users caused by temporary interruptions in the Service for the following reasons: scheduled or unscheduled technical and preventive work, technical failures of Internet providers, computer networks, servers and means, as well as illegal actions of third parties, intentional, unintentional (including negligent) actions of the User and/or force majeure. At the same time, ORIGIN undertakes to make every effort to restore the Service's functionality as soon as possible.</p>
                        <p>11.2. <b>"ORIGIN"</b> is not liable under any circumstances for infringement of intellectual property rights of third parties, if such infringement was caused by the creation, download, placement, copying or modification of information, materials or images in the Service, performed by Users of the Service.</p>
                        <p>11.3. In case of submission to <b>"ORIGIN"</b> of any claims, lawsuits, as well as any other requirements related to the use of the Service by the User or a third party from the User's account, the User is obliged to settle the above claims/requirements on their own and at their own expense and to reimburse in full all costs and losses incurred by ORIGIN.</p>
                        <p>11.4. <b>"ORIGIN"</b> has the right at any time at its discretion to terminate, restrict or terminate the User's access to the Service, including, but not limited to, in case the User violates the terms of this Agreement, applicable law applicable to the Agreement, and also if ORIGIN has grounds to consider the User's actions unscrupulous, aimed at disrupting the Service.</p>
                        <p>11.5. <b>"ORIGIN"</b> is under no circumstances responsible for violation of the legislation governing the processing of personal data if such violation occurred as a result of violation by Users or third parties to whom the personal data was transferred with the consent of the User and/or his legal representative, requirements of the legislation and conditions of this Agreement.</p>
                        <p>11.6. This limitation of liability should be applied to the maximum extent permitted by law. This limitation of liability does not apply only in cases where any specific condition of limitation of liability is expressly prohibited by the conditions specified by law. However, other provisions of this Agreement remain valid and the Parties undertake to implement them as much as possible, as well as to replace provisions that contradict the mandatory rules of applicable law with provisions as close as possible in content and permissible under the law applicable to this Agreement.</p>
                        <p>11.7. In no case will <b>"ORIGIN"</b> be liable for all losses (except for the cases provided by the current legislation) that exceed the actual price paid by the User for the use of the Service, or Ksh 500 depending on which the amount is less.</p>
                        <p>11.8. The use of the services provided by <b>"ORIGIN"</b> is at the User's own risk. The user is fully responsible for the damage caused to himself. The user is fully responsible for any damage resulting from actions or events that occur during the lease term or as a result.</p>
                        <p>11.9. The User continues to be liable for damages incurred after the lease term until <b>"ORIGIN"</b> duly inspects the leased Property (max. 24 hours) or until the Property is leased by another user.</p>
                        <p>11.10. The User is responsible for all costs and losses incurred by <b>"ORIGIN"</b> in case of non-compliance with the contractual obligations of the User, including those defined in the previous paragraphs and relating to the obligation to inform.</p>
                        <p>11.11. <b>"ORIGIN"</b> is not responsible in case of incorrect and/or unauthorized use of the Property.</p>

                        <h1>12. WARRANTIES</h1>
                        <p>12.1. The Service, including its additional functions, is provided to the User "as is", with all defects, without any warranty, without providing technical characteristics or any guarantees, and its use by the User is at your own risk. All risk associated with satisfactory quality and performance lies with the User. <b>"ORIGIN"</b> does not give and hereby waives any explicit, implied or statutory guarantees, including possible guarantees of condition, uninterrupted use, the accuracy of data, achievement of certain results, suitability for sale, satisfactory quality, suitability for a specific purpose, not violating the rights of third parties, as well as guarantees (if any) arising from the usual practice of conducting business transactions, the use of business practices, business practices. <b>"ORIGIN"</b> does not guarantee: absence of failures when using the Service by the User; that the Service will meet the requirements, that the work of the Service will be continuous or error-free, or that the Service will interact or be compatible with any other Services of <b>"ORIGIN"</b>, or that any errors in the Service will be corrected.</p>
                        <p>If the current legislation does not allow the exclusion or limitation of guarantees or the application of restrictions provided by law on consumer rights, the above exceptions and limitations are applicable to the maximum extent permitted by law.</p>
                        <p>12.2. The guarantees specified in clause 12.1 of this Agreement are an essential condition that determines the interest of <b>"ORIGIN"</b> to enter into this agreement.</p>

                        <h1>13. FINAL PROVISIONS</h1>
                        <p>13.1. <b>"ORIGIN"</b> reserves the right to immediately or without prior notice to the User to restrict or block access to the Service or take other measures against the User who violated the terms of this Agreement, without compensation to the User of the prepaid cost of services on a prepaid basis.</p>
                        <p>13.2. By registering in the Service, the User confirms his agreement with all the provisions of the Agreement, as well as its compliance with all the conditions reflected in the registration forms and necessary for successful registration.</p>
                        <p>13.3. This Agreement may be terminated by <b>"ORIGIN"</b> unilaterally, in case the User violates its terms and conditions. In this case, the User is obliged to immediately terminate access to the Service and use of Intellectual Property.</p>
                        <p>13.4. <b>"ORIGIN"</b> reserves the right to change, correct, add or delete parts of this Agreement at its discretion by posting an updated version of the Agreement at: website. Continuation of use of the Service by the User after making changes and/or additions to this Agreement means acceptance and consent of the User with such changes and/or additions.</p>
                        <p>13.5. If any provision of this Agreement is declared invalid by a court, the Parties shall nevertheless make every effort to give effect to the intentions of the Parties set forth in this Agreement, while other provisions of the Agreement shall remain in full force and effect.</p>
                        <p>13.6. Disputes between <b>"ORIGIN"</b> and Users are resolved through negotiations and in case of impossibility of such settlement in court at the location of <b>"ORIGIN"</b> in

                            accordance with the legislation of KENYA. Disputes between the Users themselves are resolved by the Users themselves without the involvement of <b>"ORIGIN"</b>.</p>
                        <p>13.7. In all other matters not provided for in this Agreement, the Parties are guided by the legislation of KENYA.</p>
                    </div>
                    <div className="termsright">
                        <b>You might also like</b>  <br /><br /><br />
                        <a onClick={() => navigate("/privacy-policy")}>Privacy Policy</a><br /><br />
                        <a onClick={() => navigateto("refund")}>Refund Policy </a>
                    </div>
                </div>
                <div className={width < 780 ? stylesss.bottomhomediv : styles.bottomhomediv} ref={ref2}>
                    {width < 780 && <div style={{ marginTop: -200 }} />}
                    <div className={styles.gettheappdiv} id="middle">
                        <div className={styles.wrapappdiv}>
                            <div className={styles.getappdivtext}>GET THE APP</div>
                            <div className={styles.applogosparent2}><Iphonelogo className={styles.iphonelogo} /><Googlelogo className={styles.googlelogo} onClick={() => { window.open("https://play.google.com/store/apps/details?id=com.theiplug.iplug", "_blank") }} /></div>
                        </div>
                    </div>
                    <div className={styles.almostbottomdiv}>
                        <div className={scrollup ? styles.bottomdivfooterout : styles.bottomdivfooter} ref={bottomref}>
                            <div className={styles.wrapcontents} >
                                <div className={styles.footercontentone}>
                                    <div className={styles.footercontentonetext}>Social </div>
                                    <div className={styles.sociallinks}>
                                        <div className={styles.sociallinkdiv}>
                                            <FaFacebook />
                                        </div>
                                        <div className={styles.sociallinkdiv}>
                                            <FaInstagramSquare />
                                        </div>
                                        <div className={styles.sociallinkdiv}>
                                            <FaLinkedinIn />
                                        </div>
                                        <div className={styles.sociallinkdiv}>
                                            <FaYoutubeSquare />
                                        </div>
                                        <div className={styles.sociallinkdiv}>
                                            <FaWhatsapp />
                                        </div>
                                    </div>
                                </div>
                                <div className={styles.footercontentone}>
                                    <img src={require("../assets/blackicon.png")} />
                                </div>
                                <div className={styles.footercontentlast}>
                                    <div className={styles.footercontentonetext}>Contacts </div>
                                    <div className={styles.footercontentonemail}>mail: info@originafrica.com</div>
                                    <div className={styles.footercontentonemail}>tel:  +254 0115066574</div>
                                    <div className={styles.footercontentonemail}>Physical Address: Krishna Centre, Woodvale Grove, Westlands, Nairobi, Kenya</div>
                                    <div className={styles.footercontentonemail}>Postal Address: P.O. Box 4215 – 00100 NAIROBI</div>
                                </div>
                            </div>
                            <br /> <br />
                            <hr color="white" />
                            <div style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <div className={styles.footerreserved}>© 2023 Iplug ~ ORIGIN EXPLORATION TECHNOLOGIES LTD. All Rights Reserved.</div>
                            </div>
                            <div style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <div className={styles.footerreservedbuttons} onClick={() => navigate("/terms-and-conditions")}>Terms & Conditions</div>
                                <div className={styles.footerreservedbuttons} onClick={() => navigate("/privacy-policy")}>Privacy Policy</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Terms;