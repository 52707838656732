import React, { useState, useRef, useEffect } from "react";
import { Link, NavigationType, useLocation, useNavigate } from "react-router-dom";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { useScrollDirection } from 'react-use-scroll-direction'
import styles from './home.module.css';
import Header from "./header";
import { FaAngleDown, FaArrowCircleDown, FaBeer, FaFacebook, FaInstagramSquare, FaLinkedinIn, FaWhatsapp, FaYoutubeSquare } from 'react-icons/fa';
import { ReactComponent as Iphonelogo } from './../assets/applogo.svg';
import { ReactComponent as Googlelogo } from './../assets/googlelogo.svg';
import { Slide, Fade } from 'react-slideshow-image';
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation, Autoplay } from "swiper";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import 'react-slideshow-image/dist/styles.css'
import useOnScreen from "./detectonview";
import { delay } from "./defaults";
import Zoomin from "./Components/zoomin";
import { homeimagesarray } from "./arrays";


const Homepage = () => {

    const navigate = useNavigate()
    const ref = useRef()
    const ref2 = useRef()
    const ref3 = useRef()
    const ref4 = useRef()
    const bottomref = useRef()
    const [scrollup, setscrollup] = useState(false);
    const [loading, setloading] = useState(true);

    const { isScrollingDown } = useScrollDirection()

    const [scrollPosition, setScrollPosition] = useState(0);
    const handleScroll = () => {
        const position = window.pageYOffset;
        setScrollPosition(position);
    };

    setTimeout(() => {
        setloading(false)
    }, delay);

    useEffect(() => {
        scrollToTop()
        window.addEventListener('scroll', handleScroll, { passive: true });

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    function scrollToTop() {
        window.scrollTo(0, 0);
    }






    //scroll page to view all restaurants
    const viewmiddle = () => {
        document.getElementById("middle").scrollIntoView({ behavior: 'smooth' });
    }




    return (
        <div onWheel={event => {
            if (event.nativeEvent.wheelDelta > 0) {
                setscrollup(true)
            } else {
                setscrollup(false)
            }
        }}>
            {loading && <Zoomin />}
            <div className={styles.homebody}>
                <div className={styles.firstdiv}>
                    <Header scrolldown={isScrollingDown} scrollPosition={scrollPosition} />
                    <div className={styles.nomoretext}>NO MORE</div>
                    <div className={styles.nomoretext2}>WITHOUT BATTERY</div>
                    <div className={styles.nomoretextbelow}>Get the app and find the nearest</div>
                    <div className={styles.nomoretextbelow2}>Iplug Powerbank</div>
                    <div className={styles.applogosparent}><Iphonelogo className={styles.iphonelogo} /><Googlelogo className={styles.googlelogo} onClick={() => { window.open("https://play.google.com/store/apps/details?id=com.theiplug.iplug", "_blank") }} /></div>

                    <div className={styles.homephoneslider}>
                        <Swiper
                            slidesPerView={1}
                            spaceBetween={0}
                            slidesPerGroup={1}
                            loop={true}
                            autoplay={{
                                delay: 2000,
                                disableOnInteraction: false,
                            }}
                            loopFillGroupWithBlank={true}
                            shortSwipes={false}
                            color={"white"}
                            navigation={false}
                            modules={[Autoplay, Pagination, Navigation]}
                            style={{
                                "--swiper-pagination-color": "#000",
                                "--swiper-navigation-color": "#000",
                                "--swiper-pagination-bullets": "none",
                                "--swiper-navigation-size": "20px"
                            }}
                        >
                            {Array.isArray(homeimagesarray) && homeimagesarray.map((val, key) => {

                                return (
                                    <SwiperSlide key={key} className={styles.testimonialdiv}>
                                        <img src={val} />
                                    </SwiperSlide>
                                )
                            })

                            }
                        </Swiper>
                    </div>
                    <div style={{ width: "100%", justifyContent: "center", alignItems: "center", display: "flex", position: "absolute" }}>
                        <div className={styles.bounce} onClick={() => viewmiddle()}>
                            <FaAngleDown color="white" size={30} />
                        </div>
                    </div>
                    <div className={styles.middlehomediv}>
                        <img className={styles.homephone} src={require("./../assets/mobbi/phone.png")} />
                    </div>
                </div>
                <div className={styles.hideimage}>
                    <div className={styles.image}></div>
                    <div ref={ref}>
                        <img src={require("./../assets/mobbi/lady1.jpg")} className={styles.rightfixedimage} />
                    </div>
                    <div ref={ref4}>
                        <img src={require("./../assets/mobbi/lady2.jpg")} className={styles.rightfixedimage2} />
                    </div>
                </div>
                <div className={styles.middledivwrap}>
                    <div ref={ref3}>
                        <div className={styles.iplugdescriptionleft}>
                            Iplug is the first Powerbank Rental<br />
                            Service that combines high <br />
                            technology and design in an <br />
                            increasingly dynamic and <br />
                            demanding world.<br /><br />
                            Iplug is not a simple Powerbank<br />
                            Sharing Service, but the only one<br />
                            to have the innovative Fast Charge <br />
                            technology that allows you to<br />
                            recharge any type of device in a <br />
                            few minutes with total freedom of<br />
                            movement.<br /><br />
                            <div className={styles.discoverbutton} onClick={() => navigate("how-it-works")} >
                                DISCOVER HOW IT WORKS
                            </div>
                        </div>
                    </div>
                </div>
                <div className={styles.bottomdivimageswrap}  >
                    <div className={styles.wrapmaxresdefault2}>
                        <img src={require("./../assets/mobbi/mobbi2.jpg")} className={styles.maxresdefault2} />
                        <div className={styles.howitworks}>
                            <div className={styles.imagebuttondescription} onClick={() => navigate("/how-it-works")}>HOW IT WORKS</div>
                            <div className={styles.morebutton} onClick={() => navigate("how-it-works")}>MORE</div>
                        </div>
                    </div>
                    <div className={styles.wrapmaxresdefault2}>
                        <img src={require("./../assets/mobbi/mobbi1.jpg")} className={styles.maxresdefault2} />
                        <div className={styles.howitworks}>
                            <div className={styles.imagebuttondescription} onClick={() => navigate("/rates")}>RATES</div>
                            <div className={styles.morebutton} onClick={() => navigate("rates")}>MORE</div>
                        </div>
                    </div>
                </div>
                <div className={styles.bottomhomediv} ref={ref2}>
                    <div className={styles.gettheappdiv} id="middle">
                        <div className={styles.wrapappdiv}>
                            <div className={styles.getappdivtext}>GET THE APP</div>
                            <div className={styles.applogosparent2}><Iphonelogo className={styles.iphonelogo} /><Googlelogo onClick={() => { window.open("https://play.google.com/store/apps/details?id=com.theiplug.iplug", "_blank") }} className={styles.googlelogo} /></div>
                        </div>
                    </div>
                    <div className={styles.almostbottomdiv}>
                        <div className={scrollup ? styles.bottomdivfooterout : styles.bottomdivfooter} ref={bottomref}>
                            <div className={styles.wrapcontents} >
                                <div className={styles.footercontentone}>
                                    <div className={styles.footercontentonetext}>Social </div>
                                    <div className={styles.sociallinks}>
                                        <div className={styles.sociallinkdiv}>
                                            <FaFacebook />
                                        </div>
                                        <div className={styles.sociallinkdiv}>
                                            <FaInstagramSquare />
                                        </div>
                                        <div className={styles.sociallinkdiv}>
                                            <FaLinkedinIn />
                                        </div>
                                        <div className={styles.sociallinkdiv}>
                                            <FaYoutubeSquare />
                                        </div>
                                        <div className={styles.sociallinkdiv}>
                                            <FaWhatsapp />
                                        </div>
                                    </div>
                                </div>
                                <div className={styles.footercontentone}>
                                    <img src={require("../assets/blackicon.png")} />
                                </div>
                                <div className={styles.footercontentlast}>
                                    <div className={styles.footercontentonetext}>Contacts </div>
                                    <div className={styles.footercontentonemail}>mail: info@originafrica.com</div>
                                    <div className={styles.footercontentonemail}>tel:  +254 0115066574</div>
                                    <div className={styles.footercontentonemail}>Physical Address: Krishna Centre, Woodvale Grove, Westlands, Nairobi, Kenya</div>
                                    <div className={styles.footercontentonemail}>Postal Address: P.O. Box 4215 – 00100 NAIROBI</div>
                                </div>
                            </div>
                            <br /> <br />
                            <hr color="white" />
                            <div style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <div className={styles.footerreserved}>© 2023 Iplug ~ ORIGIN EXPLORATION TECHNOLOGIES LTD. All Rights Reserved.</div>
                            </div>
                             <div style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <div className={styles.footerreservedbuttons} onClick={() => navigate("/terms-and-conditions")}>Terms & Conditions</div>
                                <div className={styles.footerreservedbuttons} onClick={() => navigate("/privacy-policy")}>Privacy Policy</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Homepage;