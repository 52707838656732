import React, { useState, useRef, useEffect } from "react";
import { Link, NavigationType, useLocation, useNavigate } from "react-router-dom";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { useScrollDirection } from 'react-use-scroll-direction'
import styles from './home.module.css';
import styless from './how.module.css';
import become from "./become.module.css";
import stories from "./stories.module.css";
import Header from "./header";
import { FaAngleDown, FaArrowCircleDown, FaBeer, FaFacebook, FaInstagramSquare, FaLinkedinIn, FaWhatsapp, FaYoutubeSquare } from 'react-icons/fa';
import { ReactComponent as Iphonelogo } from './../assets/applogo.svg';
import { ReactComponent as Googlelogo } from './../assets/googlelogo.svg';
import { Slide, Fade } from 'react-slideshow-image';
import axios from "axios";
import 'react-slideshow-image/dist/styles.css'
import useOnScreen from "./detectonview";
import useWindowDimensions from "./detectwindowsize";
import Loadingspinner from "./Components/loading";
import Successspinner from "./Components/successspinner";
import Zoomin from "./Components/zoomin";
import { delay } from "./defaults";


const Become = () => {
    const navigate = useNavigate()
    const { height, width } = useWindowDimensions();
    //form
    const [email, setemail] = useState('');
    const [name, setname] = useState('');
    const [country, setcountry] = useState('');
    const [company, setcompany] = useState('');
    const [message, setmessage] = useState('');

    const [error, seterror] = useState('');
    const [success, setsuccess] = useState('');
    const [progress, setprogress] = useState('');


    const ref2 = useRef()
    const ref3 = useRef()
    const ref4 = useRef()
    const ref5 = useRef()
    const bottomref = useRef()
    const isVisible3 = useOnScreen(ref3)
    const isVisible4 = useOnScreen(ref4)
    const isVisible5 = useOnScreen(ref5)
    const [scrollup, setscrollup] = useState(false);
    const [loading, setloading] = useState(true);

    const { isScrollingDown } = useScrollDirection()

    const [scrollPosition, setScrollPosition] = useState(0);
    const handleScroll = () => {
        const position = window.pageYOffset;
        setScrollPosition(position);
    };

    setTimeout(() => {
        setloading(false)
    }, delay);

    useEffect(() => {
        scrollToTop()
        window.addEventListener('scroll', handleScroll, { passive: true });

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    function scrollToTop() {
        window.scrollTo(0, 0);
    }

    const base_url = "https://api.theiplug.com/public/api/users/"
    const url = base_url + "becomepartner"


    const submit = () => {
        seterror("")
        setprogress(true)
        axios.defaults.headers = { 'Content-Type': 'application/json', Authorization: 'Bearer ' + "test" }
        axios.post(url, {
            name: name,
            email: email,
            country: country,
            message: message
        }).then(response => {
            setprogress(false)
            if (!response.data.error) {
                setsuccess("Sent.We'll get back to you asap")
                setTimeout(() => {
                    setsuccess("")
                    setname("")
                    setemail("")
                    setcountry("")
                    setmessage("")
                }, 3000);
            } else {
                seterror(response.data.message)
            }
            // 
        }).catch(error => {
            setprogress(false)
            seterror("there was an error try again later")
        });
    }


    //scroll page to view all restaurants
    const viewmiddle = () => {
        document.getElementById("middle").scrollIntoView({ behavior: 'smooth' });
    }




    return (
        <div onWheel={event => {
            if (event.nativeEvent.wheelDelta > 0) {
                setscrollup(true)
            } else {
                setscrollup(false)
            }
        }}>
            {progress && <Loadingspinner />}
            {success && <Successspinner message={success} />}
            {loading && <Zoomin />}
            <div className={styles.homebody}>
                <Header scrolldown={isScrollingDown} scrollPosition={scrollPosition} />
                <div className={become.firstdivhow}>
                    {width > 780 && <div className={styles.bouncingwrap}>
                        <div className={styles.bounce} onClick={() => viewmiddle()}>
                            <FaAngleDown color="white" size={30} />
                        </div>
                    </div>}
                    <div className={styles.howitworkswrap}>
                        <div className={become.partnerhead}>BECOME A IPLUG PARTNER</div>
                    </div>
                </div>
                <br /> <br />
                <div style={{
                    backgroundColor: "white"
                }}>
                    <div className={styless.powerbank}>
                        Widen your horizons. Let your location to be discovered by our<br />
                        users, your new clients.<br /><br />
                        To Become a part of IPLUG world, choose your perfect Iplug<br />
                        Station and refine your environment.<br /><br />
                    </div>
                </div>
                <div className={styles.wrapmaxresdefault}>
                    <img src={require("./../assets/Become Partner/B.jpg")} className={styles.maxresdefault} />
                    <div className={styles.watchvideodiv}>
                        <FaYoutubeSquare /> &nbsp; WATCH THE VIDEO
                    </div>
                </div>
                <br /><br /><br />
                {/* mobile divs */}
                <div ref={ref3}>
                    {width < 780 && <div>
                        <div className={stories.iplugdescriptionleft}>
                            GOLD ETERNITY
                        </div>
                        <div className={stories.zoomwrap}>
                            <div className={stories.zoomwrap2}>
                                <div className={stories.rightfixedimagehow}>
                                    <img src={require("./../assets/Become Partner/yellow.jpg")} className={stories.zoomedimage} />
                                </div>
                            </div>
                        </div>
                    </div>}
                </div>
                <div ref={ref4}>
                    {width < 780 && <div>
                        <div className={stories.iplugdescriptionleft}>
                            BORDEAUX
                        </div>
                        <div className={stories.zoomwrap}>
                            <div className={stories.zoomwrap2}>
                                <div className={stories.rightfixedimagehow}>
                                    <img src={require("../assets/Become Partner/bord.png")} className={stories.zoomedimage} />
                                </div>
                            </div>
                        </div>
                    </div>}
                </div>
                <div ref={ref5}>
                    {width < 780 && <div>
                        <div className={stories.iplugdescriptionleft}>
                            DEEP BLACK<br /><br />
                        </div>
                        <div className={stories.zoomwrap}>
                            <div className={stories.zoomwrap2}>
                                <div className={stories.rightfixedimagehow}>
                                    <img src={require("./../assets/Become Partner/deep.png")} className={stories.zoomedimage} />
                                </div>
                            </div>
                        </div>
                    </div>}
                </div>
                {width > 780 &&
                    <div className={styless.getapphow2} ref={ref3}>
                        {isVisible3 &&
                            <div className={styless.rightfixedimagehow}>
                                GOLD ETERNITY
                            </div>}
                        <div>
                            {isVisible3 &&
                                <img src={require("./../assets/Become Partner/yellow.jpg")} className={styless.iplugdescriptionleft} />
                            }
                        </div>
                    </div>}
                {width > 780 && <div className={styless.getapphow2} ref={ref4}>
                    {isVisible4 && <img src={require("./../assets/Become Partner/bord.png")} className={styless.rightfixedimagehow} />}
                    <div >
                        {isVisible4 && <div className={!isVisible5 && styless.iplugdescriptionleft}>
                            BORDEAUX
                        </div>}
                    </div>
                </div>}
                {width > 780 && <div className={styless.getapphow2} ref={ref5}>
                    {isVisible5 &&
                        <div className={styless.rightfixedimagehow}>
                            DEEP BLACK<br /><br />
                        </div>}
                    <div>
                        {isVisible5 &&
                            <img src={require("./../assets/Become Partner/deep.png")} className={styless.iplugdescriptionleft} />
                        }
                    </div>
                </div>}
                <div className={become.wrapform}>
                    <div className={become.wantheader}>Want to become a Iplug partner ?</div><br />
                    <div className={become.wantheaderbottom}>Complete the form below and our team will contact you as soon as possible</div>
                    <div className={become.contactusform}>
                        <div className={become.wrapinputdivs}>
                            <div className={become.inputwrap}>
                                NAME AND SURNAME:<br />
                                <input type={"text"} value={name} onChange={(e) => setname(e.target.value)} />
                            </div>
                            <div className={become.inputwrap}>
                                EMAIL:<br />
                                <input type={"text"} value={email} onChange={(e) => setemail(e.target.value)} />
                            </div>
                            <div className={become.inputwrap}>
                                COUNTRY<br />
                                <input type={"text"} value={country} onChange={(e) => setcountry(e.target.value)} />
                            </div>
                            <div className={become.inputwrap}>
                                COMPANY<br />
                                <select name="company" id="company">
                                    <option value="bar">Bar</option>
                                    <option value="restaurant">Restaurant</option>
                                    <option value="hotel">Hotel</option>
                                    <option value="tobbacco">Tobbacco shop</option>
                                    <option value="disco">Disco</option>
                                    <option value="other">Other</option>
                                </select>
                            </div>

                        </div><br />
                        <div className={become.wrappartnerbottom}>
                            <div className={become.wrapmessage}>
                                SPECIAL REQUESTS  <br />
                                <textarea rows="10" cols="90" name="message" form="usrform" value={message} onChange={(e) => setmessage(e.target.value)}>
                                    Enter text here...
                                </textarea>
                            </div>
                            <div className={become.wrapbuttonsubmit}>
                                <div style={{ display: "flex" }}>
                                    <input type="checkbox" />&nbsp;&nbsp;
                                    <text className={become.checkboxtext}>I ACCEPT THE PRIVACY POLICY AND I AGREE TO THE PROCESSING OF PERSONAL DATA</text>
                                </div>

                                <div style={{ display: "flex", marginTop: 5 }}>
                                    <input type="checkbox" />&nbsp;&nbsp;
                                    <text className={become.checkboxtext}>SUBSCRIBE TO NEWSLETTER</text>
                                </div>
                                <text className={become.checkboxtext}>
                                    &nbsp; Click here to read privacy policy terms
                                    * required fields
                                </text>
                                <div className={become.discoverbutton} onClick={() => submit()}>
                                    SUBMIT
                                </div>

                            </div>


                        </div>


                    </div>

                </div>

                <div className={styles.bottomhomediv} ref={ref2}>

                    <div className={styles.gettheappdiv} id="middle">
                        <div className={styles.wrapappdiv}>
                            <div className={styles.getappdivtext}>GET THE APP</div>
                            <div className={styles.applogosparent2}><Iphonelogo className={styles.iphonelogo} /><Googlelogo className={styles.googlelogo} /></div>
                        </div>
                    </div>
                           <div className={styles.almostbottomdiv}>
                        <div className={scrollup ? styles.bottomdivfooterout : styles.bottomdivfooter} ref={bottomref}>
                            <div className={styles.wrapcontents} >
                                <div className={styles.footercontentone}>
                                    <div className={styles.footercontentonetext}>Social </div>
                                    <div className={styles.sociallinks}>
                                        <div className={styles.sociallinkdiv}>
                                            <FaFacebook />
                                        </div>
                                        <div className={styles.sociallinkdiv}>
                                            <FaInstagramSquare />
                                        </div>
                                        <div className={styles.sociallinkdiv}>
                                            <FaLinkedinIn />
                                        </div>
                                        <div className={styles.sociallinkdiv}>
                                            <FaYoutubeSquare />
                                        </div>
                                        <div className={styles.sociallinkdiv}>
                                            <FaWhatsapp />
                                        </div>
                                    </div>
                                </div>
                                <div className={styles.footercontentone}>
                                    <img src={require("../assets/blackicon.png")} />
                                </div>
                                <div className={styles.footercontentlast}>
                                    <div className={styles.footercontentonetext}>Contacts </div>
                                    <div className={styles.footercontentonemail}>mail: info@originafrica.com</div>
                                    <div className={styles.footercontentonemail}>tel:  +254 0115066574</div>
                                    <div className={styles.footercontentonemail}>Physical Address: Krishna Centre, Woodvale Grove, Westlands, Nairobi, Kenya</div>
                                    <div className={styles.footercontentonemail}>Postal Address: P.O. Box 4215 – 00100 NAIROBI</div>
                                </div>
                            </div>
                            <br /> <br />
                            <hr color="white" />
                            <div style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <div className={styles.footerreserved}>© 2023 Iplug ~ ORIGIN EXPLORATION TECHNOLOGIES LTD. All Rights Reserved.</div>
                            </div>
                             <div style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <div className={styles.footerreservedbuttons} onClick={() => navigate("/terms-and-conditions")}>Terms & Conditions</div>
                                <div className={styles.footerreservedbuttons} onClick={() => navigate("/privacy-policy")}>Privacy Policy</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Become;