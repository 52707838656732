import React, { useState, useRef, useEffect } from "react";
import { Link, NavigationType, useLocation, useNavigate } from "react-router-dom";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { useScrollDirection } from 'react-use-scroll-direction'
import styles from './home.module.css';
import styless from './how.module.css';
import stylesss from "./rate.module.css";
import Header from "./header";
import { FaAngleDown, FaArrowCircleDown, FaBeer, FaFacebook, FaInstagramSquare, FaLinkedinIn, FaWhatsapp, FaYoutubeSquare } from 'react-icons/fa';
import { ReactComponent as Iphonelogo } from './../assets/applogo.svg';
import { ReactComponent as Googlelogo } from './../assets/googlelogo.svg';
import { Slide, Fade } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css'
import useOnScreen from "./detectonview";
import useWindowDimensions from "./detectwindowsize";
import Loadingspinner from "./Components/loading";
import { delay } from "./defaults";
import Zoomin from "./Components/zoomin";


const Privacy = () => {
    const navigate = useNavigate()
    const { height, width } = useWindowDimensions();
    const ref = useRef()
    const ref2 = useRef()
    const bottomref = useRef()

    const [scrollup, setscrollup] = useState(false);
    const [loading, setloading] = useState(true);

    const { isScrollingDown } = useScrollDirection()

    const [scrollPosition, setScrollPosition] = useState(0);
    const handleScroll = () => {
        const position = window.pageYOffset;
        setScrollPosition(position);
    };

    setTimeout(() => {
        setloading(false)
    }, delay);

    useEffect(() => {
        scrollToTop()
        window.addEventListener('scroll', handleScroll, { passive: true });

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    function scrollToTop() {
        window.scrollTo(0, 0);
    }




    //scroll page to view all restaurants
    const viewmiddle = () => {
        document.getElementById("middle").scrollIntoView({ behavior: 'smooth' });
    }




    return (
        <div onWheel={event => {
            if (event.nativeEvent.wheelDelta > 0) {
                setscrollup(true)
            } else {
                setscrollup(false)
            }
        }}>
            {loading && <Zoomin />}
            <div className={styles.homebody}>
                <Header scrolldown={isScrollingDown} scrollPosition={scrollPosition} />
                <div className="wraptermswhole">
                    <div className="termsleft">
                        <div className="termstext">Home\Privacy Policy</div>
                        <h1>Privacy Policy</h1>
                        <p>Origin Exploration Technologies Ltd has created the "Origin" application as a free application. This Service is provided by Origin free of charge and is intended for use as is.</p>
                        <p>This page is used to inform visitors about our policy regarding the collection, use, and disclosure of personal information if someone chooses to use our Service.</p>
                        <p>If you choose to use our Service, you agree to the collection and use of information regarding this policy. The personal information we collect is used to provide and improve the Service. We will not use or share your information with anyone except as described in this Privacy Policy.</p>
                        <p>Terms used in this Privacy Policy have the same meanings as in our Terms and Conditions, which are available in the Origin application unless otherwise specified in this Privacy Policy.</p>

                        <h2>Collection and Use of Information</h2>
                        <p>To improve performance using our Service, we may ask you to provide certain personal information, including, but not limited to, Apple's IDFA, Android Advertising ID, user phone number, GPS location, name, and surname. The information we request will be stored by us and used as described in this privacy policy.</p>
                        <p>The application uses third-party services that can collect information that is used to identify you.</p>
                        <p>Links to the privacy policies of third-party service providers used by the application:</p>
                        <ul>
                            <li>Ipay</li>
                            <li>Apple Pay</li>
                            <li>Google Play Services</li>
                        </ul>

                        <h2>Journal Data</h2>
                        <p>We want to inform you that when you use our Service, in case of an error in the application, we collect data and information (through third-party products) on your phone under the name Log Data. This log data may include information such as your device's Internet Protocol ("IP"), device name, operating system version, application configuration when using our Service, time and date of use of the Service, and other statistics.</p>

                        <h2>Cookies</h2>
                        <p>Cookies are files with a small amount of data that are typically used as anonymous unique identifiers. They are sent to your browser from the websites you visit and stored in your device's internal storage.</p>
                        <p>This service does not use "cookies" explicitly. However, the application may use the code of third-party vendors and libraries that use cookies to collect information and improve their services. You have the ability to accept or decline these cookies and know when a cookie is being sent to your device. If you choose to opt-out of our cookies, you may not be able to use certain parts of the Service.</p>

                        <h2>Service Providers</h2>
                        <p>We may hire third-party companies and individuals for the following reasons:</p>
                        <ul>
                            <li>To facilitate our Service</li>
                            <li>Provide services on our behalf</li>
                            <li>To provide services related to the Service</li>
                            <li>To help us analyze how our Service is used</li>
                        </ul>
                        <p>We want to inform users of this Service that these third parties have access to their personal information. The reason is to perform the tasks assigned to them on our behalf. However, they are obliged not to disclose or use the information for any other purpose.</p>

                        <h2>Security</h2>
                        <p>We value your trust in providing us with your personal information, so we strive

                            to use commercially acceptable means to protect it. But remember that no method of transmission over the Internet or method of electronic storage is 100% secure and reliable, and we cannot guarantee absolute security.</p>

                        <h2>Links to Other Sites</h2>
                        <p>This Service may contain links to other sites. If you click on third-party links, you will go to this site. Please note that we do not control these external sites, so we strongly encourage you to review the privacy policies of these websites. We do not control or are responsible for the content, privacy policies, or practices of any third-party sites or services.</p>

                        <h2>Privacy for Children</h2>
                        <p>This Service is not intended for persons under 13 years of age. We knowingly do not collect personal information from children under 13 years of age. If we find that a child under the age of 13 has provided us with personal information, we will immediately remove it from our servers. If you are a parent or guardian and you know that your child has provided us with personal information, please contact us so that we can take the necessary action.</p>

                        <h2>Changes to this Privacy Policy</h2>
                        <p>We may update our Privacy Policy from time to time. Therefore, we encourage you to periodically review this page for any changes. We will notify you of any changes by posting a new Privacy Policy on this page.</p>
                        <p>This policy comes into force on 2023-05-10.</p>

                        <h2>Contact Us</h2>
                        <p>If you have any questions or suggestions about our Privacy Policy, feel free to contact us at:</p>
                        <p>Email: info@originafrica.com</p>
                        <p>Customer Service: +254 0115066574</p>
                        <p>Skype: originsupport</p>
                        <p>Twitter: @originafrica</p>
                        <p>Physical Address: Krishna Centre, Woodvale Grove, Westlands, Nairobi, Kenya</p>
                        <p>Postal Address: P.O. Box 4215 – 00100 NAIROBI</p>

                    </div>
                </div>
                <div className={width < 780 ? stylesss.bottomhomediv : styles.bottomhomediv} ref={ref2}>
                    {width < 780 && <div style={{ marginTop: -200 }} />}
                    <div className={styles.gettheappdiv} id="middle">
                        <div className={styles.wrapappdiv}>
                            <div className={styles.getappdivtext}>GET THE APP</div>
                            <div className={styles.applogosparent2}><Iphonelogo className={styles.iphonelogo} /><Googlelogo className={styles.googlelogo} onClick={() => { window.open("https://play.google.com/store/apps/details?id=com.theiplug.iplug", "_blank") }} /></div>
                        </div>
                    </div>
                           <div className={styles.almostbottomdiv}>
                        <div className={scrollup ? styles.bottomdivfooterout : styles.bottomdivfooter} ref={bottomref}>
                            <div className={styles.wrapcontents} >
                                <div className={styles.footercontentone}>
                                    <div className={styles.footercontentonetext}>Social </div>
                                    <div className={styles.sociallinks}>
                                        <div className={styles.sociallinkdiv}>
                                            <FaFacebook />
                                        </div>
                                        <div className={styles.sociallinkdiv}>
                                            <FaInstagramSquare />
                                        </div>
                                        <div className={styles.sociallinkdiv}>
                                            <FaLinkedinIn />
                                        </div>
                                        <div className={styles.sociallinkdiv}>
                                            <FaYoutubeSquare />
                                        </div>
                                        <div className={styles.sociallinkdiv}>
                                            <FaWhatsapp />
                                        </div>
                                    </div>
                                </div>
                                <div className={styles.footercontentone}>
                                    <img src={require("../assets/blackicon.png")} />
                                </div>
                                <div className={styles.footercontentlast}>
                                    <div className={styles.footercontentonetext}>Contacts </div>
                                    <div className={styles.footercontentonemail}>mail: info@originafrica.com</div>
                                    <div className={styles.footercontentonemail}>tel:  +254 0115066574</div>
                                    <div className={styles.footercontentonemail}>Physical Address: Krishna Centre, Woodvale Grove, Westlands, Nairobi, Kenya</div>
                                    <div className={styles.footercontentonemail}>Postal Address: P.O. Box 4215 – 00100 NAIROBI</div>
                                </div>
                            </div>
                            <br /> <br />
                            <hr color="white" />
                            <div style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <div className={styles.footerreserved}>© 2023 Iplug ~ ORIGIN EXPLORATION TECHNOLOGIES LTD. All Rights Reserved.</div>
                            </div>
                             <div style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <div className={styles.footerreservedbuttons} onClick={() => navigate("/terms-and-conditions")}>Terms & Conditions</div>
                                <div className={styles.footerreservedbuttons} onClick={() => navigate("/privacy-policy")}>Privacy Policy</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Privacy;


